<template>
  <b-form>
    <b-row>
      <b-col>
        <h1>Mail template</h1>
      </b-col>
      <b-col cols="auto">
        <b-button class="mb-5 save-button" @click="save()">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab class="mb-5" title="detail">
        <b-form-group>
          <b-form-checkbox v-model="mail.hide_logo">
            Hide logo
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Name">
          <b-form-input v-model="mail.name"></b-form-input>
        </b-form-group>
        <b-form-group label="BCC address">
          <b-form-input v-model="mail.bcc"></b-form-input>
        </b-form-group>
        <b-form-group label="Greeting">
          <b-form-input v-model="mail.greeting"></b-form-input>
        </b-form-group>
        <b-form-group label="Subject">
          <b-form-input v-model="mail.subject"></b-form-input>
        </b-form-group>
        <b-form-group label="Before text">
          <b-form-checkbox v-model="mail.before_is_html">
            Is HTML
          </b-form-checkbox>
          <b-form-checkbox v-model="useHTMLEditorBefore">
            use HTML editor
          </b-form-checkbox>
          <quill-editor
            v-model="mail.before_text"
            :options="editorOption"
            v-if="useHTMLEditorBefore"
          />
          <b-form-textarea v-model="mail.before_text" v-else> </b-form-textarea>
        </b-form-group>
        <b-form-group label="Button label">
          <b-form-input v-model="mail.action_text"></b-form-input>
        </b-form-group>
        <b-form-group label="Button URL">
          <b-form-input v-model="mail.action_url"></b-form-input>
        </b-form-group>
        <b-form-group label="After text">
          <b-form-checkbox v-model="mail.after_is_html">
            Use HTML
          </b-form-checkbox>
          <b-form-checkbox v-model="useHTMLEditorAfter">
            use HTML editor
          </b-form-checkbox>
          <quill-editor
            v-model="mail.after_text"
            :options="editorOption"
            v-if="useHTMLEditorAfter"
          />
          <b-form-textarea v-model="mail.after_text" v-else> </b-form-textarea>
        </b-form-group>
        <b-form-group label="Ending">
          <b-form-input v-model="mail.ending"></b-form-input>
        </b-form-group>
        <!-- <h2>Preview</h2>
      <span v-html="page.html[language]"></span> -->
      </b-tab>
      <b-tab title="Invite">
        <b-form-checkbox v-model="mail.include_invite">
          Include invite?
        </b-form-checkbox>
        <div v-if="mail.include_invite">
          <b-form-group label="Title">
            <b-form-input v-model="mail.invite_title"></b-form-input>
          </b-form-group>
          <b-form-group label="Location">
            <b-form-input v-model="mail.invite_location"></b-form-input>
          </b-form-group>
          <b-form-group label="Description">
            <b-form-input v-model="mail.invite_description"></b-form-input>
          </b-form-group>
          <b-form-group label="Start date">
            <flat-pickr
              v-model="mail.invite_start_date"
              :config="flatPickerConfig"
            ></flat-pickr>
          </b-form-group>
          <b-form-group label="End date">
            <flat-pickr
              v-model="mail.invite_end_date"
              :config="flatPickerConfig"
            ></flat-pickr>
          </b-form-group>
        </div>
      </b-tab>
      <b-tab title="Send messages">
        <vuetable ref="sent-table" :data="filteredMails" :fields="fields">
          <template slot="recipients" scope="props">
            <div
              v-for="(recipient, index) in props.rowData.recipients"
              :key="index"
            >
              {{ recipient }}
            </div>
          </template>
          <template slot="receivedat" scope="props">
            {{ props.rowData.receivedat | moment("DD/MM/YYYY, h:mm:ss a") }}
          </template>
        </vuetable>
      </b-tab>
    </b-tabs>
  </b-form>
</template>
<script>
import Vuetable from "vuetable-2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  data() {
    return {
      flatPickerConfig: {
        enableTime: true,
        utc: true,
        altFormat: "Y-m-d H:i",
        dateFormat: "Y-m-d\\TH:i:S",
        altInput: true,
      },
      index: "",
      useHTMLEditorBefore: false,
      useHTMLEditorAfter: false,
      afterIsHtml: false,
      editorOption: {},
      submitted: false,
      fields: [
        {
          name: "messageid",
          title: "id",
        },
        {
          name: "from",
          title: "from",
        },
        {
          name: "recipients",
          title: "recipients",
        },
        {
          name: "status",
          title: "status",
        },
        {
          name: "receivedat",
          title: "recieved at",
        },
      ],
    };
  },
  components: {
    Vuetable,
    flatPickr,
  },
  async created() {
    this.isLoading = true;
    if (this.$route.query.id) {
      await this.doFetch();
      await this.fetchSendMails();
    } else {
      this.$store.commit("Mails/EMPTY_CURRENT", this.styling);
    }
    this.isLoading = false;
  },
  methods: {
    async doFetch() {
      await this.$store.dispatch("Mails/fetchById", this.$route.query.id);
    },
    async fetchSendMails() {
      await this.$store.dispatch("Mails/fetchByTemplate", this.mail?.id);
    },
    async save() {
      this.isLoading = true;
      this.submitted = true;
      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid) {
          return;
        }
        if (this.mail.id) {
          await this.$store.dispatch("Mails/update", this.mail);
        } else {
          await this.$store.dispatch("Mails/create", this.mail);
        }
        this.$root.$bvToast.toast("Page settings saved!", {
          variant: "success",
        });
        this.submitted = false;
      });
      this.isLoading = false;
    },
  },
  computed: {
    styling() {
      return this.$store.state.Styling.styling;
    },
    mails() {
      return this.$store.state.Mails.mails;
    },
    mail() {
      return this.$store.state.Mails.mail;
    },
    filteredMails() {
      return this.$store.state.Mails.filteredMails;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>
